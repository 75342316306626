import { useEffect, useState, useRef } from "react";
import "./header.scss";
import classNames from "classnames";
import logo from "../assets/images/logo_white.png";
import logo_negative from "../assets/images/logo_negative.png";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
export function Header() {
  const [isTop, setIsTop] = useState(false); // scroll 상태
  const [isToggle, setIsToggle] = useState(false); // 모바일 토글버튼 활성화 상태
  const [extraCase, setExtraCase] = useState(false); // 스크롤과 메뉴 활성화 상태
  const [isHover, setIsHover] = useState(false);
  const headerRef = useRef(null);
  const location = useLocation();

  const navigation = useNavigate();
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const menuSelectHandler = ({ menu }) => {
    if (location.pathname !== "/") {
      navigation("/");
    }
    if (headerRef.current) {
      const solutionElement = headerRef.current.nextElementSibling;
      const serviceElement = solutionElement.nextElementSibling;
      const stack = serviceElement.nextElementSibling;
      const outputElement = stack.nextElementSibling;
      const historyElement = outputElement.nextElementSibling;
      const clientElement =
        historyElement.nextElementSibling.nextElementSibling;
      if (menu === "solution") {
        solutionElement.scrollIntoView({ behavior: "smooth" });
      }
      if (menu === "service") {
        serviceElement.scrollIntoView({ behavior: "smooth" });
      }
      if (menu === "stack") {
        stack.scrollIntoView({ behavior: "smooth" });
      }
      if (menu === "output") {
        outputElement.scrollIntoView({ behavior: "smooth" });
      }
      if (menu === "history") {
        historyElement.scrollIntoView({ behavior: "smooth" });
      }
      if (menu === "request") {
        clientElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const handleScroll = () => {
    if (window.scrollY === 0) {
      setIsTop(false);
    } else {
      setIsTop(true);
    }
  };

  const handleMouseEvent = ({ status }) => {
    if (status === "enter") setIsHover(true);
    else setIsHover(false);
  };

  return (
    <header
      ref={headerRef}
      className={classNames("header scrolled", {
        scrolled: isTop || extraCase,
      })}
      onMouseEnter={() => {
        handleMouseEvent({ status: "enter" });
      }}
      onMouseLeave={() => {
        handleMouseEvent({ status: "leave" });
      }}
    >
      <nav className="wrap">
        <div className="header-item-wrapper">
          <Link to="/">
            <span className="header-logo-img">
              <img src={isHover || isTop ? logo_negative : logo_negative} />
            </span>
          </Link>
          <span className="header-button-mobile">
            <input class="burger-check" type="checkbox" id="burger-check" />
            <label
              class="burger-icon"
              for="burger-check"
              onClick={() => {
                if (!isTop) {
                  setExtraCase(true);
                  setIsToggle(!isToggle);
                } else {
                  setExtraCase(false);
                  setIsToggle(!isToggle);
                }
              }}
            >
              <span
                className={classNames("burger-sticks", { not: isToggle })}
              ></span>
            </label>
          </span>
        </div>
        <ul
          className={classNames("header-menu-group", {
            active: isToggle,
          })}
        >
          <li
            onClick={() => {
              menuSelectHandler({ menu: "solution" });
            }}
          >
            솔루션
          </li>
          <li
            onClick={() => {
              menuSelectHandler({ menu: "service" });
            }}
          >
            서비스
          </li>
          <li
            onClick={() => {
              menuSelectHandler({ menu: "stack" });
            }}
          >
            개발스택
          </li>

          <li
            onClick={() => {
              menuSelectHandler({ menu: "output" });
            }}
          >
            산출물
          </li>
          <li
            onClick={() => {
              menuSelectHandler({ menu: "history" });
            }}
          >
            회사연혁
          </li>
          <li
            onClick={() => {
              menuSelectHandler({ menu: "request" });
            }}
          >
            문의하기
          </li>
        </ul>
      </nav>
    </header>
  );
}

import { useSpring, animated } from "react-spring";
import React, { useEffect, useRef, useState } from "react";
import { Fade } from "react-reveal";
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import styles from "./styles.module.css";
import { style } from "@mui/system";
import { BrowserView, MobileView } from "react-device-detect";
import { Parallax as ParallaxAnimation } from "react-scroll-parallax";
import "./stackinfo.scss";
// import "./styles.module.css";
export default function StackInfo() {
  const containerRef = useRef(null);
  const parallaxParent = useRef(null);
  const parallaxChild = useRef(null);
  const [scrollT, setScrollT] = useState(false);
  const [gradientValue, setGradientValue] = useState(
    "linear-gradient(rgb(148, 193, 177) 0%, rgb(127, 150, 238) 50%, rgb(175, 228, 228) 100%)"
  );

  const alignItem = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  };

  return (
    <>
      <BrowserView>
        <div
          ref={containerRef}
          style={{ position: "relative", height: "100vh" }}
        >
          {/* parallax */}

          <Parallax
            ref={parallaxParent}
            speed={0.3}
            pages={2}
            style={{ top: 0 }}
            onScrollCapture={(e) => {
              if (!scrollT) {
                console.log("이거보임2?");
                containerRef.current.scrollIntoView();
                setScrollT((prev) => !prev);
              }
              if (
                parallaxChild.current.content.current.style.background !=
                gradientValue
              ) {
                parallaxChild.current.content.current.style.background =
                  gradientValue;
              }
            }}
          >
            {/* page1-title */}
            <ParallaxLayer
              offset={0}
              sticky={{ start: 0, end: 1 }}
              style={{ ...alignItem }}
            >
              <Fade bottom duration={1800}>
                <p className={styles.title}>Retained technology</p>
              </Fade>
            </ParallaxLayer>
            {/* page2-content box */}
            <ParallaxLayer offset={1} sticky={{ start: 1, end: 2 }} style={{}}>
              <Parallax
                ref={parallaxChild}
                pages={7}
                speed={0.3}
                enabled={true}
                style={{ top: 0 }}
                onScrollCapture={(e) => {
                  parallaxParent.current.scrollTo(1.5);
                  containerRef.current.scrollIntoView = () => {};
                }}
              >
                <ParallaxLayer
                  offset={1}
                  sticky={{ start: 1, end: 2 }}
                  style={{ ...alignItem }}
                  className={styles.textLeft}
                >
                  <div className={styles.textBox}>
                    <h1 className={styles.sectionTitle}>Language</h1>
                    <p className={styles.textItem}>․ Java</p>
                    <p className={styles.textItem}>․ React</p>
                    <p className={styles.textItem}>․ Angular</p>
                    <p className={styles.textItem}>․ Ionic</p>
                    <p className={styles.textItem}>․ React Native</p>
                    <p className={styles.textItem}>․ PHP</p>
                    <p className={styles.textItem}>․ Python</p>
                  </div>
                </ParallaxLayer>

                <ParallaxLayer
                  offset={1}
                  sticky={{ start: 1.3, end: 2 }}
                  style={{ ...alignItem }}
                  className={styles.textCenter}
                >
                  <div className={styles.textBox}>
                    <h1 className={styles.sectionTitle}>Server</h1>
                    <p className={styles.textItem}>․ Spring Boot</p>
                    <p className={styles.textItem}>․ Java</p>
                    <p className={styles.textItem}>․ Tomcat</p>
                    <p className={styles.textItem}>․ AWS</p>
                    <p
                      className={styles.textItem}
                      style={{ visibility: "hidden" }}
                    >
                      fake
                    </p>{" "}
                    <p
                      className={styles.textItem}
                      style={{ visibility: "hidden" }}
                    >
                      fake
                    </p>
                    <p
                      className={styles.textItem}
                      style={{ visibility: "hidden" }}
                    >
                      fake
                    </p>
                  </div>
                </ParallaxLayer>
                <ParallaxLayer
                  offset={1}
                  sticky={{ start: 1.6, end: 2 }}
                  style={{ ...alignItem }}
                  className={styles.textRight}
                >
                  <div className={styles.textBox}>
                    <h1 className={styles.sectionTitle}>Database</h1>
                    <p className={styles.textItem}>․ MySQL</p>
                    <p className={styles.textItem}>․ MariaDB</p>
                    <p className={styles.textItem}>․ NoSQL</p>
                    <p
                      className={styles.textItem}
                      style={{ visibility: "hidden" }}
                    >
                      fake
                    </p>
                    <p
                      className={styles.textItem}
                      style={{ visibility: "hidden" }}
                    >
                      fake
                    </p>
                    <p
                      className={styles.textItem}
                      style={{ visibility: "hidden" }}
                    >
                      fake
                    </p>
                    <p
                      className={styles.textItem}
                      style={{ visibility: "hidden" }}
                    >
                      fake
                    </p>
                  </div>
                </ParallaxLayer>

                <ParallaxLayer
                  offset={3}
                  sticky={{ start: 3, end: 4 }}
                  style={{ ...alignItem }}
                  className={styles.textSpaceLeft}
                >
                  <div className={styles.textBox}>
                    <h1 className={styles.sectionTitle}>Front End</h1>
                    <p className={styles.textItem}>․ React</p>
                    <p className={styles.textItem}>․ Angular</p>
                    <p className={styles.textItem}>․ Wordpress</p>
                    <p className={styles.textItem}>․ Ionic(Angular, React)</p>
                    <p className={styles.textItem}>․ React Native</p>
                  </div>
                </ParallaxLayer>
                <ParallaxLayer
                  offset={3}
                  sticky={{ start: 3.5, end: 4 }}
                  style={{ ...alignItem }}
                  className={styles.textSpaceRight}
                >
                  <div className={styles.textBox}>
                    <h1 className={styles.sectionTitle}>
                      Statistics & Data Collector
                    </h1>
                    <p className={styles.textItem}>․ Google Analyrics(GA)</p>
                    <p className={styles.textItem}>․ Firebase</p>
                    <p className={styles.textItem}>․ 서버 모니터링</p>
                    <p className={styles.textItem}>․ 퍼포먼스 마케팅</p>
                  </div>
                </ParallaxLayer>

                <ParallaxLayer
                  offset={5}
                  sticky={{ start: 5, end: 6 }}
                  style={{ ...alignItem }}
                  className={styles.textSpaceLeft}
                >
                  <div className={styles.textBox}>
                    <h1 className={styles.sectionTitle}>Etc</h1>
                    <p className={styles.textItem}>․ OpenCv</p>
                    <p className={styles.textItem}>․ AR</p>
                    <p className={styles.textItem}>․ 영상 스트리밍</p>
                    <p className={styles.textItem}>․ 채팅(FCM)</p>
                    <p className={styles.textItem}>․ SSL</p>
                    <p className={styles.textItem}>․ OCR</p>
                    <p className={styles.textItem}>
                      ․ 자연어 처리(TF-IDF, BERT)
                    </p>
                  </div>
                </ParallaxLayer>

                <ParallaxLayer
                  offset={3}
                  sticky={{ start: 5.5, end: 6 }}
                  style={{ ...alignItem }}
                  className={styles.textSpaceRight}
                >
                  <div className={styles.textBox}>
                    <h1 className={styles.sectionTitle}>Notice</h1>
                    <p className={styles.textItem}>․ SMS</p>
                    <p className={styles.textItem}>․ Push Notification</p>
                    <p className={styles.textItem}>․ Mail Sender</p>
                    <p
                      className={styles.textItem}
                      style={{ visibility: "hidden" }}
                    >
                      fake
                    </p>
                    <p
                      className={styles.textItem}
                      style={{ visibility: "hidden" }}
                    >
                      fake
                    </p>
                    <p
                      className={styles.textItem}
                      style={{ visibility: "hidden" }}
                    >
                      fake
                    </p>
                    <p
                      className={styles.textItem}
                      style={{ visibility: "hidden" }}
                    >
                      fake
                    </p>
                    <p
                      className={styles.textItem}
                      style={{ visibility: "hidden" }}
                    >
                      fake
                    </p>
                  </div>
                </ParallaxLayer>
              </Parallax>
            </ParallaxLayer>
          </Parallax>

          {/* mobile */}
        </div>
      </BrowserView>
      <MobileView>
        <div className="MobileWrapper">
          <div className="SectionMain ">
            <ParallaxAnimation speed={-70}>
              <Fade bottom duration={1800}>
                <p className={styles.title}>Retention Technology</p>
              </Fade>
            </ParallaxAnimation>
          </div>
          <div className="Sections ">
            <div className="Sections01">
              <div>
                <Fade bottom duration={1200}>
                  <div className={styles.textBox}>
                    <h1 className={styles.sectionTitle}>Language</h1>
                    <p className={styles.textItem}>․ Java</p>
                    <p className={styles.textItem}>․ React</p>
                    <p className={styles.textItem}>․ Angular</p>
                    <p className={styles.textItem}>․ Ionic</p>
                    <p className={styles.textItem}>․ React Native</p>
                    <p className={styles.textItem}>․ PHP</p>
                    <p className={styles.textItem}>․ Python</p>
                  </div>
                </Fade>
              </div>
              <Fade bottom duration={1500}>
                <div>
                  <div className={styles.textBox}>
                    <h1 className={styles.sectionTitle}>Server</h1>
                    <p className={styles.textItem}>․ Spring Boot</p>
                    <p className={styles.textItem}>․ Java</p>
                    <p className={styles.textItem}>․ Tomcat</p>
                    <p className={styles.textItem}>․ AWS</p>
                    <p
                      className={styles.textItem}
                      style={{ visibility: "hidden" }}
                    >
                      fake
                    </p>{" "}
                    <p
                      className={styles.textItem}
                      style={{ visibility: "hidden" }}
                    >
                      fake
                    </p>
                    <p
                      className={styles.textItem}
                      style={{ visibility: "hidden" }}
                    >
                      fake
                    </p>
                  </div>
                </div>
              </Fade>
              <Fade bottom duration={1800}>
                <div>
                  <div className={styles.textBox}>
                    <h1 className={styles.sectionTitle}>Database</h1>
                    <p className={styles.textItem}>․ MySQL</p>
                    <p className={styles.textItem}>․ MariaDB</p>
                    <p className={styles.textItem}>․ NoSQL</p>
                    <p
                      className={styles.textItem}
                      style={{ visibility: "hidden" }}
                    >
                      fake
                    </p>
                    <p
                      className={styles.textItem}
                      style={{ visibility: "hidden" }}
                    >
                      fake
                    </p>
                    <p
                      className={styles.textItem}
                      style={{ visibility: "hidden" }}
                    >
                      fake
                    </p>
                    <p
                      className={styles.textItem}
                      style={{ visibility: "hidden" }}
                    >
                      fake
                    </p>
                  </div>
                </div>
              </Fade>
            </div>
            <div className="Sections02">
              <Fade bottom dration={1500}>
                <div className={styles.textBox}>
                  <h1 className={styles.sectionTitle}>Front End</h1>
                  <p className={styles.textItem}>․ React</p>
                  <p className={styles.textItem}>․ Angular</p>
                  <p className={styles.textItem}>․ Wordpress</p>
                  <p className={styles.textItem}>․ Ionic(Angular, React)</p>
                  <p className={styles.textItem}>․ React Native</p>
                </div>
              </Fade>
              <Fade bottom dration={2000}>
                <div className={styles.textBox} style={{ width: "50%" }}>
                  <h1 className={styles.sectionTitle}>
                    Statistics & Data Collector
                  </h1>
                  <p className={styles.textItem}>․ Google Analyrics(GA)</p>
                  <p className={styles.textItem}>․ Firebase</p>
                  <p className={styles.textItem}>․ 서버 모니터링</p>
                  <p className={styles.textItem}>․ 퍼포먼스 마케팅</p>
                </div>
              </Fade>
            </div>
            <div className="Sections03">
              <Fade bottom dration={1500}>
                <div className={styles.textBox}>
                  <h1 className={styles.sectionTitle}>Etc</h1>
                  <p className={styles.textItem}>․ OpenCv</p>
                  <p className={styles.textItem}>․ AR</p>
                  <p className={styles.textItem}>․ 영상 스트리밍</p>
                  <p className={styles.textItem}>․ 채팅(FCM)</p>
                  <p className={styles.textItem}>․ SSL</p>
                  <p className={styles.textItem}>․ OCR</p>
                  <p className={styles.textItem}>․ 자연어 처리(TF-IDF, BERT)</p>
                </div>
              </Fade>
              <Fade bottom duration={2000}>
                <div className={styles.textBox}>
                  <h1 className={styles.sectionTitle}>Notice</h1>
                  <p className={styles.textItem}>․ SMS</p>
                  <p className={styles.textItem}>․ Push Notification</p>
                  <p className={styles.textItem}>․ Mail Sender</p>
                  <p
                    className={styles.textItem}
                    style={{ visibility: "hidden" }}
                  >
                    fake
                  </p>
                  <p
                    className={styles.textItem}
                    style={{ visibility: "hidden" }}
                  >
                    fake
                  </p>
                  <p
                    className={styles.textItem}
                    style={{ visibility: "hidden" }}
                  >
                    fake
                  </p>
                  <p
                    className={styles.textItem}
                    style={{ visibility: "hidden" }}
                  >
                    fake
                  </p>
                  <p
                    className={styles.textItem}
                    style={{ visibility: "hidden" }}
                  >
                    fake
                  </p>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </MobileView>
    </>
  );
}

import "./history.scss";
import { Fade } from "react-reveal";
export default function Hisotry01() {
  return (
    <div className="section10-container">
      <div className="section10-content wrap">
        <div className="section10-txt">
          <Fade bottom duration={1200}>
            <h4>About us</h4>
          </Fade>
          <Fade bottom duration={1500}>
            <h1>자이플랩 연혁</h1>

            <p>
              여러분과 함께하기위해
              <br /> 걸어온 발자취입니다.
            </p>
          </Fade>
        </div>
        <div className="section10-history-map">
          <section class="timeline">
            <div class="container">
              <div class="timeline-item">
                <div class="timeline-img">
                  <span>2022</span>
                </div>
                <div class="timeline-content js--fadeInLeft">
                  <br />
                  <h2>한국벤처투자 시드 유치</h2>

                  <p></p>
                </div>
              </div>

              <div class="timeline-item">
                <div class="timeline-img">
                  <span>2021</span>
                </div>

                <div class="timeline-content js--fadeInLeft">
                  <h2>
                    경기스타트업캠퍼스(판교) 입주선정
                    <br />
                    GBSA 민간투자연계형 기술창업지원사업 선정
                  </h2>

                  <p></p>
                </div>
              </div>

              <div class="timeline-item">
                <div class="timeline-img">
                  <span>2020</span>
                </div>

                <div class="timeline-content js--fadeInRight">
                  <h2>
                    한국관광공사, 관광분야 엑셀레이팅
                    <br /> 사업 선정 기업 시드투자 유치
                  </h2>

                  <p></p>
                </div>
              </div>

              <div class="timeline-item">
                <div class="timeline-img">
                  <span>2019</span>
                </div>

                <div class="timeline-content js--fadeInRight">
                  <h2>
                    신용보증기금 Startup-NEST 선정 기업
                    <br /> 벤처기업 인증
                    <br />
                    기업부설연구소 설립
                    <span
                      style={{
                        color: "#BEBEBE",
                        fontSize: "0.9rem",
                      }}
                    >
                      ()연구개발전담부서
                    </span>
                    <br />
                    IP관리 솔루션 개발
                    <br />
                  </h2>

                  <p></p>
                </div>
              </div>

              <div class="timeline-item">
                <div class="timeline-img">
                  <span>2018</span>
                </div>

                <div class="timeline-content js--fadeInRight">
                  <h2>
                    {" "}
                    지식재산 통합 플랫폼 서비스 "자이플" 론칭
                    <br />
                    GBSA 경기 스타트업-랩 입주선정
                  </h2>

                  <p></p>
                </div>
              </div>

              <div class="timeline-item">
                <div class="timeline-img">
                  <span>2017</span>
                </div>

                <div class="timeline-content js--fadeInRight">
                  <h2>중소벤처기업진흥공단 창업성공패키지 선정 기업</h2>

                  <p></p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { TextareaAutosize } from "@mui/material";
export default function PaymentForm({ formDataHandler }) {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom></Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <TextField
            required
            id="cardName"
            name="projectType"
            label="프로젝트 유형"
            fullWidth
            autoComplete="cc-name"
            variant="standard"
            onChange={(e) => {
              formDataHandler(e);
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            required
            id="cardNumber"
            label="참고사이트"
            name="referenceSite"
            fullWidth
            autoComplete="cc-number"
            variant="standard"
            onChange={(e) => {
              formDataHandler(e);
            }}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            required
            id="expDate"
            name="projectMoney"
            label="프로젝트 예산"
            fullWidth
            autoComplete="cc-exp"
            variant="standard"
            onChange={(e) => {
              formDataHandler(e);
            }}
          />
        </Grid>
        <hr />
        <Grid item xs={12} md={12}>
          <h3 style={{ fontSize: "15px", textAlign: "left", color: "gray" }}>
            문의내용
          </h3>
          <TextareaAutosize
            aria-label="minimum height"
            minRows={3}
            name="Inquiries"
            placeholder="문의하실 내용을 입력해주세요"
            style={{ width: "100%" }}
            onChange={(e) => {
              formDataHandler(e);
            }}
          />
        </Grid>

        <Grid item xs={12}></Grid>
      </Grid>
    </React.Fragment>
  );
}

import "./ServicePage";
import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";
import { UseInterval } from "../../../hooks/UseInterval";

export default function ZipleProgressbar({
  progressNum,
  progressAcc,
  interval,
  progressDepth,
}) {
  const [progressStep, setProgressStep] = useState(1);
  const [progressAccNum, setProgressAccNum] = useState(progressNum);
  const { ref, inView } = useInView();
  const initializeProgress = () => {
    setProgressStep(1);
    setProgressAccNum(progressNum);
  };

  useEffect(() => {
    initializeProgress();
  }, [progressNum]);

  const intervalHandler = () => {
    UseInterval(() => {
      if (inView) {
        if (progressStep === progressDepth) {
          initializeProgress();
        }
        setProgressAccNum((prev) => prev + progressAcc);
        setProgressStep((prev) => prev + 1);
      } else {
        clearInterval(intervalHandler);
      }
    }, interval);
  };
  intervalHandler();
  return (
    <p ref={ref} className="service-production-bar-wrapper">
      <span
        className="service-production-bar"
        style={{
          width:
            progressStep === progressDepth
              ? progressNum + "px "
              : progressAccNum + "px",
        }}
      />
    </p>
  );
}

ZipleProgressbar.defaultProps = {
  progressNum: 0,
  progressAcc: 0,
  progressDepth: 10,
  interval: 3000,
};

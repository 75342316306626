import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AddressForm from "./CustomerForm";
import PaymentForm from "./ProjectForm";
import Review from "./Review";
import { useRef } from "react";
import zipleLogo from "../../assets/images/logo_negative.png";
import "./request.scss";
import { useForm, ValidationError } from "@formspree/react";
import { Form } from "react-router-dom";
function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://ziplelab.com/">
        ziplelab.com
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const steps = ["고객정보 입력", "프로젝트 정보", "확인 및 제출"];

function getStepContent(step, formDataHandler, request) {
  switch (step) {
    case 0:
      return <AddressForm formDataHandler={formDataHandler} />;
    case 1:
      return <PaymentForm formDataHandler={formDataHandler} />;
    case 2:
      return <Review request={request} />;

    default:
      throw new Error("Unknown step");
  }
}

const theme = createTheme();

export default function Checkout() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [state, handleSubmit] = useForm("xaykpwro");
  const formRef = useRef(null);
  const [request, setRequest] = React.useState({
    companyName: "",
    homepageUrl: "",
    emailAddress: "",
    manageName: "",
    phoneNumber: "",
    projectType: "",
    referenceSite: "",
    projectMoney: "",
    Inquiries: "",
  });

  const formDataHandler = (e) => {
    setRequest({ ...request, [e.target.name]: e.target.value });
  };

  const handleNext = () => {
    if (activeStep === 0) {
      for (let x in request) {
        if (!request[x]) {
          switch (x) {
            case "companyName":
              alert("회사명을 입력 해주세요");
              break;
            case "homepageUrl":
              alert("홈페이지 URL을 입력 해주세요");
              break;
            case "emailAddress":
              continue;
            case "manageName":
              alert("담당자명을 입력 해주세요");
              break;
            case "phoneNumber":
              alert("휴대폰 번호를 입력 해주세요");
              break;
            default:
              setActiveStep(activeStep + 1);
          }
          return;
        }
      }
    }
    if (activeStep === 1) {
      for (let x in request) {
        if (!request[x]) {
          switch (x) {
            case "emailAddress":
              continue;
            case "projectType":
              alert("프로젝트 유형을 입력 해주세요");
              break;
            case "referenceSite":
              alert("참고 사이트를 입력 해주세요");
              break;
            case "projectMoney":
              alert("프로젝트 예산을 입력해주세요");
              break;
            case "Inquiries":
              continue;
            default:
              setActiveStep(activeStep + 1);
          }
          return;
        }
      }
      setActiveStep(activeStep + 1);
    }
    if (activeStep === 2) {
      setActiveStep(activeStep + 1);
      formRef.current.submit();
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar
        position="absolute"
        color="default"
        elevation={0}
        sx={{
          position: "relative",
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      ></AppBar>
      <Container
        component="main"
        maxWidth="sm"
        sx={{ mb: 4 }}
        style={{
          padding: "120px 0",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Typography
            component="h1"
            variant="h4"
            align="center"
            style={{ padding: "30px 0" }}
          >
            <img src={zipleLogo} />
          </Typography>
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography variant="h5" gutterBottom></Typography>
              <Typography variant="subtitle1">
                프로젝트 신청 문의가 완료되었습니다
              </Typography>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {getStepContent(activeStep, formDataHandler, request)}
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                {activeStep !== 0 && (
                  <Button
                    onClick={handleBack}
                    sx={{ mt: 3, ml: 1, color: "#767676" }}
                  >
                    이전
                  </Button>
                )}
                <form
                  ref={formRef}
                  onSubmit={handleSubmit}
                  style={{ visibility: "hidden" }}
                  action="https://formspree.io/f/xaykprrg"
                  method="post"
                >
                  <input
                    type={"hidden"}
                    name="회사명"
                    value={request.companyName}
                  />
                  <input
                    type={"hidden"}
                    name="홈페이지"
                    value={request.homepageUrl}
                  />
                  <input
                    type={"hidden"}
                    name="이메일"
                    value={request.emailAddress}
                  />
                  <input
                    type={"hidden"}
                    name="담당자명"
                    value={request.manageName}
                  />
                  <input
                    type={"hidden"}
                    name="핸드폰번호"
                    value={request.phoneNumber}
                  />
                  <input
                    type={"hidden"}
                    name="프로젝트 유형"
                    value={request.projectType}
                  />
                  <input
                    type={"hidden"}
                    name="참고사이트"
                    value={request.referenceSite}
                  />
                  <input
                    type={"hidden"}
                    name="프로젝트예산"
                    value={request.projectMoney}
                  />
                  <input
                    type={"hidden"}
                    name="문의사항"
                    value={request.Inquiries}
                  />
                </form>
                <Button
                  className="nextBtn"
                  variant="contained"
                  onClick={handleNext}
                  sx={{
                    mt: 3,
                    ml: 1,
                    background: "#FAFAFA",
                    color: "#767676",
                    boxShadow: "none",
                    borderRadius: 0,
                    border: "1px solid #DCDCDC",
                    width: "50%",
                    maxWidth: "250px",
                    minWidth: "200px",
                  }}
                >
                  {activeStep === steps.length - 1 ? "제출하기" : "다음으로"}
                </Button>
              </Box>
            </React.Fragment>
          )}
        </Paper>
        <Copyright />
      </Container>
    </ThemeProvider>
  );
}

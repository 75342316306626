export const serviceContents = [
  {
    id: 1,
    title: "서비스 개발의뢰",
    contents: [
      { id: 1, text: "· 서비스 기획 시작" },
      { id: 2, text: "· 개발 스택 및 개발 기간" },
      { id: 3, text: "· 예산 규모 선정" },
    ],
  },
  {
    id: 2,
    title: "서비스 개발 제안",
    contents: [
      { id: 1, text: "· 제안서 작성" },
      { id: 2, text: "· 개발 기간, 개발 스택 선정" },
      { id: 3, text: "· 투입 인력 선정" },
    ],
  },
  {
    id: 3,
    title: "개발 견적 협의",
    contents: [
      { id: 1, text: "" },
      { id: 2, text: "" },
      { id: 3, text: "" },
    ],
  },
  {
    id: 4,
    title: "과업 지시서 협의",
    contents: [
      { id: 1, text: "· 업무 범위 조율, 확정" },
      { id: 2, text: "· 개발 기간 조율, 확정" },
      { id: 3, text: "" },
    ],
  },
  {
    id: 5,
    title: "계약 진행",
    contents: [
      { id: 1, text: "· 계약내용 협의" },
      { id: 2, text: "· 외부 서비스 계약 협의" },
      { id: 3, text: "" },
    ],
  },
  {
    id: 6,
    title: "기획진행",
    contents: [
      { id: 1, text: "" },
      { id: 2, text: "" },
      { id: 3, text: "" },
    ],
  },
  {
    id: 7,
    title: "개발 진행",
    contents: [
      { id: 1, text: "" },
      { id: 2, text: "" },
      { id: 3, text: "" },
    ],
  },
  {
    id: 8,
    title: "중간 보고 진행",
    contents: [
      { id: 1, text: "" },
      { id: 2, text: "" },
      { id: 3, text: "" },
    ],
  },
  {
    id: 9,
    title: "검수 진행",
    contents: [
      { id: 1, text: "" },
      { id: 2, text: "" },
      { id: 3, text: "" },
    ],
  },
  {
    id: 10,
    title: "개발 및 납품",
    contents: [
      { id: 1, text: "" },
      { id: 2, text: "" },
      { id: 3, text: "" },
    ],
  },
];

import "./history02.scss";
export default function History02() {
  return (
    <div className="section11-container">
      <div className="section11-content wrap">
        <div className="section11-txt">
          <h1>인증 및 기타</h1>
        </div>
        <div className="section11-history-map">
          <section class="timeline">
            <div class="container">
              <div class="timeline-item">
                <div class="timeline-img">
                  <span></span>
                </div>
                <div class="timeline-content js--fadeInLeft">
                  <br />
                  <h2>벤처인증 기업</h2>
                  <p></p>
                </div>
              </div>

              <div class="timeline-item">
                <div class="timeline-img">
                  <span></span>
                </div>

                <div class="timeline-content js--fadeInLeft">
                  <br />
                  <h2>IP 솔루션 BM특허 보유기업</h2>

                  <p></p>
                </div>
              </div>

              <div class="timeline-item">
                <div class="timeline-img">
                  <span></span>
                </div>

                <div class="timeline-content js--fadeInRight">
                  <br />
                  <h2>연구개발 전담부서</h2>

                  <p></p>
                </div>
              </div>

              <div class="timeline-item">
                <div class="timeline-img">
                  <span></span>
                </div>

                <div class="timeline-content js--fadeInRight">
                  <br />
                  <h2>지식재산 리걸테크 분야 업계 1위 서비스 운영사</h2>

                  <p></p>
                </div>
              </div>

              <div class="timeline-item">
                <div class="timeline-img">
                  <span></span>
                </div>

                <div class="timeline-content js--fadeInRight">
                  <br />
                  <h2>숙박권 재거래 플랫폼 "투게인" 운영사</h2>

                  <p></p>
                </div>
              </div>

              <div class="timeline-item">
                <div class="timeline-img">
                  <span></span>
                </div>

                <div class="timeline-content js--fadeInRight">
                  <br />
                  <h2>중장비 매칭 플랫폼 "공사일이" 운영사</h2>

                  <p></p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

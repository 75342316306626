import "./output.scss";
import Slider from "react-slick";
import Carousel from "./Carousel";
import React from "react";
import img1 from "../../../assets/images/section08_img.png";
import output01 from "../../../assets/images/output01.png";
import output02 from "../../../assets/images/output02.png";
import output03 from "../../../assets/images/output03.png";
import output04 from "../../../assets/images/output04.png";
import output05 from "../../../assets/images/output05.png";
import output06 from "../../../assets/images/output06.png";
import Modal from "react-modal";
import { GrPrevious, GrNext } from "react-icons/gr";
import { Fade } from "react-reveal";
export default function OutPutsPage() {
  const settings = {
    speed: 500,
    nextArrow: (
      <div>
        <GrNext size={30} />
      </div>
    ),
    prevArrow: (
      <div>
        <GrPrevious size={30} />
      </div>
    ),
    infinite: true,
    speed: 500,
    slidesToShow: document.body.clientWidth > 768 ? 3 : 1,
    slidesToScroll: document.body.clientWidth > 768 ? 3 : 1,
  };
  return (
    <div className="ziple-output-page">
      <main className="output-slide-wrapper">
        <Fade bottom dration={1200}>
          <h2 className="output-slide-sub">자이플랩</h2>
        </Fade>
        <Fade bottom dration={1500}>
          <h1 className="output-slide-title">Portfolio</h1>
        </Fade>
        <h6 className="output-slide-description">
          제작이 완료된 자이플랩의 포트폴리오 입니다.
        </h6>
        <section className="output-slide-wrapper wrap">
          <Carousel />
        </section>
      </main>
      <main className="output-category-wrapper">
        <Fade bottom={1200}>
          <h2 className="output-category-sub">연구개발</h2>
        </Fade>
        <Fade bottom={1500}>
          <h1 className="output-category-title">
            AI 자연어 처리기반
            <br />
            문서관리 서비스
          </h1>
        </Fade>
        <div className="wrap">
          <img />
        </div>
      </main>

      <main
        className="output-slide02-wrapper wrap"
        style={{ maxWidth: "900px" }}
      >
        <h1 className="output-slide02-title">
          서비스 개발 완료 후 제공되는 <br /> 산출물 입니다.
        </h1>
        <div className="section09-slide-container ">
          <Slider {...settings}>
            <div className="section09-slide-item">
              <div>
                <img src={output01} />
                <h3>스토리보드(서비스 기획안)</h3>
                <p>
                  · 웹, 앱 상세 프로세스 정의
                  <br />
                  · 내, 외부 연동 프로세스 정의
                  <br />· 서비스 시나리오 정의
                </p>
              </div>
            </div>
            <div className="section09-slide-item">
              <div>
                <img src={output02} />
                <h3>데이터 베이스 설계문서</h3>
                <p>
                  · DB 설계서
                  <br />
                  · ERD
                  <br />
                </p>
              </div>
            </div>
            <div className="section09-slide-item">
              <div>
                <img src={output03} />
                <h3>서비스 프로세스 문서</h3>
                <p>
                  · 서비스 기획 문서
                  <br />
                  <br />
                </p>
              </div>
            </div>
            <div className="section09-slide-item">
              <div>
                <img src={output04} />
                <h3>사용자 메뉴얼</h3>
                <p>
                  · 사용자의 사용법에 따른 메뉴얼 문서
                  <br />
                  · 관리자 서비스 사용자 메뉴얼 문서
                  <br />
                </p>
              </div>
            </div>
            <div className="section09-slide-item">
              <div>
                <img src={output05} />
                <h3>외부 서비스 연동 문서</h3>
                <p>
                  · Push Notification, SMS 서비스
                  <br />
                  &nbsp;&nbsp; 등 외부 서비스를 연계 기술 문서
                  <br />
                </p>
              </div>
            </div>
            <div className="section09-slide-item">
              <div>
                <img src={output06} />
                <h3>API 설계 문서</h3>
                <p>
                  · 서버 개발 시 사용한 API 설계 기술문서
                  <br />
                  · API 메뉴얼 (Swagger 기반)
                  <br />
                </p>
              </div>
            </div>
          </Slider>
        </div>
      </main>
    </div>
  );
}

import React, { useEffect } from "react";
import {
  StackedCarousel,
  ResponsiveContainer,
} from "react-stacked-center-carousel";
import { GrPrevious, GrNext } from "react-icons/gr";
// 0412
import protfolio01 from "../../../assets/images/portfolio01.png";
// ziple
import protfolio02 from "../../../assets/images/portfolio02.png";
// togain
import protfolio03 from "../../../assets/images/portfolio03.png";
// 04bridge
import protfolio04 from "../../../assets/images/portfolio04.png";

import portfolio_01 from "../../../assets/images/portfolio_01.jpg";
import portfolio_02 from "../../../assets/images/portfolio_02.jpg";
import portfolio_03 from "../../../assets/images/portfolio_03.jpg";
import portfolio_04 from "../../../assets/images/portfolio_04.jpg";
import { IoExitOutline } from "react-icons/io5";
import Modal from "react-modal";
export const data = [
  {
    cover: protfolio01,
    content: portfolio_01,
    title: "",
  },
  {
    cover: protfolio02,
    content: portfolio_02,
    title: "",
  },
  {
    cover: protfolio03,
    content: portfolio_03,
    title: "",
  },
  {
    cover: protfolio04,
    content: portfolio_04,
    title: "",
  },
];

export default function ResponsiveCarousel(props) {
  const ref = React.useRef();

  return (
    <div style={{ width: "100%", position: "relative", zIndex: 0 }}>
      <ResponsiveContainer
        carouselRef={ref}
        render={(parentWidth, carouselRef) => {
          // If you want to use a ref to call the method of StackedCarousel, you cannot set the ref directly on the carousel component
          // This is because ResponsiveContainer will not render the carousel before its parent's width is determined
          // parentWidth is determined after your parent component mounts. Thus if you set the ref directly it will not work since the carousel is not rendered
          // Thus you need to pass your ref object to the ResponsiveContainer as the carouselRef prop and in your render function you will receive this ref object
          let currentVisibleSlide = 3;
          if (parentWidth <= 1440) currentVisibleSlide = 3;
          if (parentWidth <= 1080) currentVisibleSlide = 1;
          return (
            <StackedCarousel
              ref={carouselRef}
              slideComponent={Card}
              slideWidth={parentWidth < 800 ? parentWidth - 40 : 750}
              carouselWidth={parentWidth}
              data={data}
              currentVisibleSlide={currentVisibleSlide}
              maxVisibleSlide={5}
              useGrabCursor
            />
          );
        }}
      />
      <>
        <span
          style={{
            position: "absolute",
            top: "40%",
            left: 10,
            zIndex: 10,
            cursor: "pointer",
          }}
          onClick={() => {
            ref.current?.goBack();
          }}
        >
          <GrPrevious size={25} />
        </span>

        <span
          style={{
            position: "absolute",
            top: "40%",
            right: 10,
            zIndex: 10,
            cursor: "pointer",
          }}
          onClick={() => {
            ref.current?.goNext(6);
          }}
        >
          <GrNext size={25} />
        </span>
      </>
    </div>
  );
}

// Very import to memoize your Slide component otherwise there might be performance issue
// At minimum your should do a simple React.memo(SlideComponent)
// If you want the absolute best performance then pass in a custom comparator function like below
export const Card = React.memo(function (props) {
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const customStyles = {
    content: {
      top: "53%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      background: "#ffffff",
      zIndex: 9999,
      position: "fixed",
      width: "80vw",
      height: "80vh",
      minHeight: "500px",
      maxWidth: "700px",
    },
  };

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }
  const { data, dataIndex } = props;
  const { cover } = data[dataIndex];

  return (
    <div
      onClick={() => {
        if (props.isCenterSlide) {
          openModal();
        }
      }}
      style={{
        width: "100%",
        height: 400,
        userSelect: "none",
        zIndex: 888,
      }}
      className="my-slide-component"
    >
      {" "}
      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Example Modal"
      >
        <div style={{ position: "relative" }}>
          <p
            style={{
              position: "relative",
              background: "#ffffff",
              width: "100%",
              top: 0,
              zIndex: 999,
              display: "flex",
              justifyContent: "flex-end",
              paddingBottom: "10px",
              cursor: "pointer",
            }}
          >
            <span
              onClick={(e) => {
                e.stopPropagation();
                closeModal();
              }}
            >
              <IoExitOutline size={28} />
            </span>
          </p>
          <img
            style={{ width: "100%", position: "absolute" }}
            src={data[dataIndex].content}
          />
        </div>
      </Modal>
      <img
        style={{
          height: "100%",
          width: "85%",
          objectFit: "cover",
          borderRadius: 10,
          filter: props.isCenterSlide ? "" : "brightness(0.5)",
          objectFit: "fill",
        }}
        draggable={false}
        src={cover}
      />
    </div>
  );
});

import Solution from "./SolutionPage/SolutionPage";
import { ServicePage } from "./ServicePage/ServicePage";
import OutPutsPage from "./OutPutsPage/OutPutsPage";
import HistoryPage from "./HistroyPage/Hisotry01";
import HistoryPage2 from "./HistroyPage/History02";
import RequestPage from "./RequestPage/RequestPage";
import React from "react";
import StackInfoPage from "./StackInfoPage/StackInfoPage";
export const LandingPage = () => {
  return (
    <>
      <Solution />
      <ServicePage />
      <StackInfoPage />
      <OutPutsPage />
      <HistoryPage />
      <HistoryPage2 />
      <RequestPage />
    </>
  );
};

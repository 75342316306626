import "./service.scss";
import service_ico01 from "../../../assets/images/service_icon1.png";
import service_ico02 from "../../../assets/images/service_icon2.png";
import service_ico03 from "../../../assets/images/service_icon3.png";
import service_ico04 from "../../../assets/images/service_icon4.png";
import service_ico05 from "../../../assets/images/service_icon5.png";
import service_ico06 from "../../../assets/images/service_icon6.png";
import ZipleProgressbar from "./ZipleProgressbar";
import { serviceContents } from "./serviceContent";
import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect, useState, useRef, forwardRef } from "react";
import { Fade } from "react-reveal";
export const ServicePage = forwardRef((prop) => {
  const [progressSetting, setProgressSetting] = useState({
    wrapper: 0,
    inner: 0,
  });
  const liRef = useRef(null);
  const wrapRef = useRef(null);

  const progressSizeHandler = () => {
    const wrapperWidth = wrapRef.current.getBoundingClientRect().x;
    const wrapperWidth2 = wrapRef.current.getBoundingClientRect().width;
    const innerWidth = liRef.current.getBoundingClientRect().width;
    const innerMargin = (wrapperWidth2 - innerWidth * 10) / 10;
    setProgressSetting({
      wrapper: wrapperWidth,
      inner: innerWidth + innerMargin,
    });
  };

  useEffect(() => {
    progressSizeHandler();
  }, []);

  const windowSizeHandler = () => {
    progressSizeHandler();
  };

  useEffect(() => {
    window.addEventListener("resize", windowSizeHandler);
    return () => {
      window.removeEventListener("resize", windowSizeHandler);
    };
  });

  return (
    <div className="ziple-service-page">
      {/* 서비스 카테고리 페이지 */}
      <main className="service-category wrap">
        <Fade bottom={1200}>
          <h2 className="service-category-sub">Service</h2>
        </Fade>
        <Fade bottom={1500}>
          <h1 className="service-category-title">이런 서비스가 가능해요</h1>
        </Fade>
        <ul className="service-category-list">
          <li>
            <img src={service_ico01} />
            <h6>
              DevelopmentLanguage
              <br />
              <br />
              <p>Java</p>
              <p>AR</p>
              <p>[MOBILE] React-Native</p>
              <p>[MOBILE] Flutter</p>
              <p>[MOBILE] Ionic Framework</p>
              <p>[MOBILE] Swift</p>
              <p>[MOBILE] Kotlin</p>
              <p>Angular.Js</p>
              <p>Vue.Js</p>
              <p>React.Js</p>
            </h6>

            <div className="service-category-item"></div>
          </li>

          <li>
            <img src={service_ico02} />
            <h6>
              Server Development
              <br />
              <br />
              <p>[AWS] EC2, RDS, S3, EBS</p>
              <p>[AWS] ELB, ECloudFront</p>
              <p>[Firebase] Firestore </p>
              <p>[Message Que Service]</p>
              <p>Apache Kalfa / RabbitMQ</p>
              <p>Flutter</p>
              <p>Ionic Framework</p>
              <p>Swift</p>
              <p>Kotlin</p>
              <p>Angular.Js</p>
              <p>Vue.Js</p>
              <p>React.Js</p>
            </h6>
            <div className="service-category-item"></div>
          </li>
          <li>
            <img
              style={{ width: "50px", height: "60px" }}
              src={service_ico03}
            />
            <h6 style={{ textAlign: "center" }}>
              Hybrid App
              <br />
              <br />
              <p style={{ textAlign: "left" }}>[MOBILE] React-Native</p>
              <p style={{ textAlign: "left" }}>[MOBILE] Flutter</p>
              <p style={{ textAlign: "left" }}>[MOBILE] Ionic Framework</p>
            </h6>
            <div className="service-category-item"></div>
          </li>
          <li>
            <img src={service_ico04} />
            <h6>
              Marketing
              <br />
              <br />
              <p>SEO 검색 최적화</p>
              <p>키워드 최적화</p>
              <p>타킷설정</p>
            </h6>
            <div className="service-category-item"></div>
          </li>
          <li>
            <img src={service_ico05} />
            <h6>
              Branding
              <br />
              <br />
              <p>스타트업 컨설팅</p>
              <p>R & D</p>
              <p>정부과제</p>
              <p>연구소 설립</p>
            </h6>
            <div className="service-category-item"></div>
          </li>
          <li>
            <img src={service_ico06} />
            <h6>
              Design
              <br />
              <br />
              <p>App</p>
              <p>Web Design</p>
              <p>Brand Design</p>
            </h6>
            <div className="service-category-item"></div>
          </li>
        </ul>
      </main>
      {/* 제작 및  납품 절차 */}
      <main className="service-production">
        <h1 className="service-production-title">
          Development
          <br />
          Process
        </h1>
        <h3 className="service-production-sub">제작 및 납품 절차</h3>
        {progressSetting.wrapper && (
          <ZipleProgressbar
            progressNum={progressSetting.wrapper + progressSetting.inner / 2}
            progressAcc={progressSetting.inner}
            interval={1000}
            progressDepth={11}
          />
        )}

        <div ref={wrapRef} className="service-production-progress-wrapper wrap">
          <ul className="service-production-progress ">
            {serviceContents.map((list) => (
              <li
                ref={liRef}
                key={list.id}
                className="service-production-progress-title"
              >
                <b>{list.id}</b>
                <h4>{list.title}</h4>
                <p>
                  {list.contents.map((content) => (
                    <h6 key={content.id}>{content.text}</h6>
                  ))}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </main>
    </div>
  );
});

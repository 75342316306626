import "./footer.scss";
import footerImg from "../assets/images/logo_white.png";

export function Footer() {
  return (
    <footer className="ziple-lab-footer">
      <div className="footer-contennts wrap">
        <div
          className="footer-content-txt"
          style={{ border: "none", margin: 0 }}
        >
          <div className="footer-content">
            <img src={footerImg} />
            <h3>자이플랩</h3>
            <p>사업자등록번호 : 579-87-00638</p>
            <p>대표자명 : 이정섭, 윤재원</p>
            <p>
              (주) 지식피플 
            </p>
           
          </div>
        </div>
        <div className="footer-content-txt">
          <div className="footer-content">
            <h3>CUSTOMER CENTER</h3>
            <p>
              <b>031.890.1422</b>
            </p>
            <p>상담시간 : 09:00 ~ 18:00</p>
          </div>
        </div>
        <div className="footer-content-txt">
          <div className="footer-content">
            <h3>CONTACT US</h3>
            <p>
              <b>cs@ziplelab.com</b>
            </p>
            <p>
            본사 : 경기도 성남시 분당구 판교로 289번길 20, 스타트업캠퍼스 2동 8층 5호<br/><br/>
            연구전담부서 :경기 성남시 수정구 대왕판교로 기업지원허브 창업존 713호(시흥동)
            </p>
          </div>
        </div>
        <div className="ziple-footer-right"></div>
      </div>
    </footer>
  );
}

import "./request.scss";
import { Fade } from "react-reveal";
import { Link } from "react-router-dom";
import clientImg01 from "../../../assets/images/mando_ico.png";
export default function RequestPage() {
  return (
    <div className="ziple-request">
      <main className="request-banner">
        <div
          className="request-banner-content wrap"
          style={{ maxWidth: "1000px" }}
        >
          <h1 className="request-banner-title">Project Request</h1>
          <h3 className="request-banner-description">
            <b>고객</b>의 입장에서 생각하여 <b>성공적인 프로젝트</b>를
            만들겠습니다.
          </h3>
        </div>{" "}
        <Link to={"/request"}>
          {" "}
          <span className="request-banner-button">프로젝트 문의하기</span>
        </Link>
      </main>
    </div>
  );
}

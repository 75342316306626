import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export default function AddressForm({ formDataHandler }) {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom></Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="firstName"
            name="companyName"
            label="회사명"
            fullWidth
            autoComplete="given-name"
            variant="standard"
            onChange={(e) => {
              formDataHandler(e);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastName"
            name="homepageUrl"
            label="홈페이지"
            fullWidth
            autoComplete="family-name"
            variant="standard"
            onChange={(e) => {
              formDataHandler(e);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="address1"
            name="manageName"
            label="담당자명"
            fullWidth
            autoComplete="shipping address-line1"
            variant="standard"
            onChange={(e) => {
              formDataHandler(e);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="address2"
            name="emailAddress"
            label="이메일"
            fullWidth
            type={"email"}
            autoComplete="shipping address-line2"
            variant="standard"
            onChange={(e) => {
              formDataHandler(e);
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextField
            required
            id="zip"
            name="phoneNumber"
            label="연락처"
            fullWidth
            autoComplete="shipping postal-code"
            variant="standard"
            onChange={(e) => {
              formDataHandler(e);
            }}
          />
        </Grid>

        <Grid item xs={12}></Grid>
      </Grid>
    </React.Fragment>
  );
}

import * as React from "react";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";

const products = [
  {
    name: "Product 1",
    desc: "A nice thing",
    price: "$9.99",
  },
  {
    name: "Product 2",
    desc: "Another thing",
    price: "$3.45",
  },
  {
    name: "Product 3",
    desc: "Something else",
    price: "$6.51",
  },
  {
    name: "Product 4",
    desc: "Best thing of all",
    price: "$14.11",
  },
  { name: "Shipping", desc: "", price: "Free" },
];

export default function Review({ request }) {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        요청사항 최종확인
      </Typography>
      <List disablePadding>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText secondary={"회사명"} />
          <Typography variant="body2">{request.companyName}</Typography>
        </ListItem>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText secondary={"홈페이지"} />
          <Typography variant="body2">{request.homepageUrl}</Typography>
        </ListItem>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText secondary={"이메일 주소"} />
          <Typography variant="body2">{request.emailAddress}</Typography>
        </ListItem>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText secondary={"핸드폰 번호"} />
          <Typography variant="body2">{request.phoneNumber}</Typography>
        </ListItem>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText secondary={"프로젝트 유형"} />
          <Typography variant="body2">{request.projectType}</Typography>
        </ListItem>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText secondary={"레퍼런스 사이트"} />
          <Typography variant="body2">{request.referenceSite}</Typography>
        </ListItem>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText secondary={"프로젝트 예산"} />
          <Typography variant="body2">{request.projectMoney}</Typography>
        </ListItem>
      </List>
      <Grid container spacing={2}>
        <Grid item container direction="column" xs={12} sm={6}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ mt: 2 }}
            style={{ textAlign: "left" }}
          >
            문의사항
          </Typography>
          <Grid container>{request.Inquiries}</Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

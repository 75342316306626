import "./solution.scss";
import videoSrc from "../../../assets/videos/ziple_main.mov";
import { Fade } from "react-reveal";
import { useInView } from "react-intersection-observer";
import { useCallback } from "react";
import processImg01 from "../../../assets/images/process01.png";
import processImg02 from "../../../assets/images/process02.png";
import processImg03 from "../../../assets/images/process03.png";
import Networking_perspective_matte from "../../../assets/images/Networking_perspective_matte.png";
import classnames from "classnames";

export default function SolutionPage() {
  const { ref, inView } = useInView();
  const isDeskTop = useCallback(() => {
    if (document.body.clientWidth <= 768) {
      return true;
    }
    return false;
  }, []);

  return (
    <div className="ziple-solution-page">
      {/* 메인섹션 영상 */}
      <main className="ziple-video">
        <p className="ziple-main-title wrap">
          <Fade bottom duration={1300}>
            <h2>당신의 프로젝트를 더 빛나게</h2>
          </Fade>
          <Fade bottom duration={1800}>
            <h2>자이플 랩,</h2>
          </Fade>
        </p>
        <video src={videoSrc} loop muted autoPlay></video>
      </main>
      {/* 자이플 소개 섹션 */}
      <main className="solution-info wrap">
        <div className="solution-info-txt">
          <p className="solution-info-txt-title">
            {isDeskTop() ? (
              <Fade bottom duration={1200}>
                {" "}
                <h2>
                  스타트업 프로젝트 개발 전문
                  <br /> 매칭솔루션 개발
                </h2>
              </Fade>
            ) : (
              <>
                {" "}
                <h2>
                  스타트업 프로젝트 개발 전문
                  <br /> 매칭솔루션 개발
                </h2>
              </>
            )}
            {isDeskTop() ? (
              <Fade bottom={1200}>
                {" "}
                <p className="solution-info-txt-content">
                  자이플랩은 다양한 전문가들로 구성되어 있습니다
                  <br />
                  다양한 프로젝트 경험을 통해 새로운 경험과 노하우를 쌓아가고
                  있습니다
                  <br />
                  검증된 프로세스로 고객의 상상을 현실로 만들어 드립니다.
                </p>
              </Fade>
            ) : (
              <p className="solution-info-txt-content">
                자이플랩은 다양한 전문가들로 구성되어 있습니다
                <br />
                다양한 프로젝트 경험을 통해 새로운 경험과 노하우를 쌓아가고
                있습니다
                <br />
                검증된 프로세스로 고객의 상상을 현실로 만들어 드립니다.
              </p>
            )}
          </p>
          <div className="solutuon-info-animation">
            <ul>
              <li>
                <img src={processImg01} />
                <p>
                  고객의
                  <br />
                  니즈를 이해
                </p>
              </li>
              <li>
                <img src={processImg02} />
                <p>
                  빠르고
                  <br />
                  합리적으로
                </p>
              </li>{" "}
              <li>
                <img src={processImg03} />
                <p>
                  퀄리티 높은
                  <br />
                  결과물
                </p>
              </li>
              <span ref={ref} className="arrow_container">
                <span className={classnames("arrow", { active: inView })}>
                  <span className="arrow-inner"></span>
                </span>
              </span>
            </ul>
          </div>
        </div>

        <div className="solution-info-img">
          <img src={Networking_perspective_matte} />
        </div>
      </main>
      {/* 자이플 소개 섹션 */}
      <main className="solution-team-message">
        <div className="solution-team-content wrap">
          <span className="solution-team-img" />
          <span className="solution-team-img" />

          <h1 className="solution-team-message-title">
            저희 서비스는 파트너의 프로젝트를 이해하는 것부터 시작합니다.
          </h1>
          <h2 className="solution-team-message-sub">
            파트너의 디지털 비즈니스에 적합한 최적화된 도구를 만들어드립니다.
          </h2>
          <ul className="solution-team-message-stack">
            <li>JAVA</li>
            <li>Python</li>
            <li>ReactNaive</li>
            <li>Ionic</li>
            <li>Bigdata</li>
            <li>PerformanceMarketing</li>
            <li>NLU</li>

            <li>Angular</li>
          </ul>
        </div>
      </main>
    </div>
  );
}
